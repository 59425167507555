import { FormattedMessage } from 'react-intl';
import styles from './case-studies-summary.module.scss';
import imgPinPoint from './../../../assets/images/clients/case-studies/case-studies-pinpoint.webp';
import imgAmazon from './../../../assets/images/clients/case-studies/case-studies-amazon.webp';
import imgWelfi from './../../../assets/images/clients/case-studies/case-studies-welfi.webp';
import imgThomsom from './../../../assets/images/clients/case-studies/case-studies-thomson.webp';
import imgErth from './../../../assets/images/clients/case-studies/case-studies-erth.webp';
import imgBudderfly from './../../../assets/images/clients/case-studies/case-studies-budderfly.webp';
import imgDollarGeneral from './../../../assets/images/clients/case-studies/case-studies-dollarGeneral.webp';
import imgDigicel from './../../../assets/images/clients/case-studies/case-studies-digicel.webp';

import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import { AmazonLogo2, BudderflyLogo2, DollarGeneralLogo2, ErthLogo2, PinPointLogo2, ThomsonReutersLogo2, WelfiLogo2, DigicelLogo } from '../../../components';
import Slider from 'react-slick';

export const CaseStudiesSummary = ({ context }) => {

    const dataCaseStudies = [
        {
            id: 'amazon',
            url: '/amazon',
            img: <img src={imgAmazon} width={258} height={182} className={styles.image} alt='outsourcing-services-client-success-amazon' />,
            logo: <div style={{ margin: '-10px', marginLeft: '-12px' }}><AmazonLogo2 size={{ width: '138', height: '70' }} /></div>,
            text: <FormattedMessage id="clients.caseStudies-summary-amazon" />
        },
        {
            id: 'dollar-general',
            url: '/dollar-general',
            img: <img src={imgDollarGeneral} width={258} height={182} className={styles.image} alt='outsourcing-services-client-success-dollar-general' />,
            logo: <div style={{ margin: '-10px', marginLeft: '-8px' }}><DollarGeneralLogo2 size={{ width: '171', height: '70' }} /></div>,
            text: <FormattedMessage id="clients.caseStudies-summary-dollar-general" />
        },
        {
            id: 'thomson-reuters',
            url: '/thomson-reuters',
            img: <img src={imgThomsom} width={258} height={182} className={styles.image} alt='outsourcing-services-client-success-thomson-reuters' />,
            logo: <div style={{ margin: '-10px', marginLeft: '-8px' }}><ThomsonReutersLogo2 size={{ width: '223', height: '70' }} /></div>,
            text: <FormattedMessage id="clients.caseStudies-summary-thomsom" />
        },
        {
            id: 'budderfly',
            url: '/budderfly',
            img: <img src={imgBudderfly} width={258} height={182} className={styles.image} alt='outsourcing-services-client-success-budderfly' />,
            logo: <div style={{ margin: '-10px', marginLeft: '0px' }}><BudderflyLogo2 size={{ width: '143', height: '70' }} /></div>,
            text: <FormattedMessage id="clients.caseStudies-summary-budderfly" />
        },
        {
            id: 'pinpoint',
            url: '/pinpoint',
            img: <img src={imgPinPoint} width={258} height={182} className={styles.image} alt='outsourcing-services-client-success-pinpoint' />,
            logo: <div style={{ margin: '-10px', marginLeft: '-8px' }}><PinPointLogo2 size={{ width: '122', height: '70' }} /></div>,
            text: <FormattedMessage id="clients.caseStudies-summary-pinPoint" />
        },
        
        {
            id: 'welfi',
            url: '/welfi',
            img: <img src={imgWelfi} width={258} height={182} className={styles.image} alt='outsourcing-services-client-success-welfi' />,
            logo: <div style={{ margin: '-10px', marginLeft: '-8px' }}><WelfiLogo2 size={{ width: '119', height: '70' }} /></div>,
            text: <FormattedMessage id="clients.caseStudies-summary-welfi" />
        },
        {
            id: 'erth',
            url: '/erth',
            img: <img src={imgErth} width={258} height={182} className={styles.image} alt='outsourcing-services-client-success-erth' />,
            logo: <div style={{ margin: '-10px', marginLeft: '0px' }}><ErthLogo2 size={{ width: '93', height: '70' }} /></div>,
            text: <FormattedMessage id="clients.caseStudies-summary-erth" />
        },
        {
            id: 'digicel',
            url: '/digicel',
            img: <img src={imgDigicel} width={258} height={182} className={styles.image} alt='outsourcing-services-client-success-digicel' />,
            logo: <div style={{ margin: '-10px', marginLeft: '0px' }}><DigicelLogo size={{ width: '88', height: '70' }} /></div>,
            text: <FormattedMessage id="clients.caseStudies-summary-digicel" />
        },
      
        
    ]
    const lang = localStorage.getItem('lang')
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [sliderToShow, setSliderToShow] = useState(7)

    const [isManualScroll, setIsManualScroll] = useState(false);

    const [containerRef, isVisible] = useIntersectionObserver();
    const [speed,setSpeed] = useState(4000);

    const [cursor, setCursor] = useState('default');

    
    let sliderRef = useRef(null);

    //Manejo de ancho de ventana
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        if (windowWidth > 1350) {
            setSliderToShow(4);
            return
        }
        if (windowWidth > 1025) {
            setSliderToShow(3);
            return
        }
        if (windowWidth > 640) {
            setSliderToShow(2);
            return
        }
        setSliderToShow(1);
        return;
    }, [windowWidth])


    //Detener al hacer hover
    useEffect(() => {
        const handleMouseEnter = () => {
            // setIsManualScroll(true);
        };
        const handleMouseLeave = () => {
            setSpeed(4000);
            setIsManualScroll(false);
        };
        const node = containerRef.current;
        if (node) {
            node.addEventListener('mouseenter', handleMouseEnter);
            node.addEventListener('mouseleave', handleMouseLeave);

            return () => {
                node.removeEventListener('mouseenter', handleMouseEnter);
                node.removeEventListener('mouseleave', handleMouseLeave);
            };
        }
    }, []);


    useEffect(()=>{
        if(isManualScroll){
            setSpeed(1000);
        }else{
            setSpeed(4000);
        }
    },[isVisible]);

    var settings = {
        dots: sliderToShow === 1,
        infinite: true,
        speed: speed,
        slidesToShow: sliderToShow,
        autoplay: !isManualScroll,
        autoplaySpeed: 0,
        className: "clientsSummary-slider",
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        cssEase: 'linear',
        pauseOnFocus: false, // Deshabilitar pausa en focus
        pauseOnDotsHover: false, // Deshabilitar pausa en hover de dots
        draggable: true,
        swipe: true,
        swipeToSlide: true,
        pauseOnHover: true,
        appendDots: dots => {
            return (
                <div
                    style={{
                        backgroundColor: "transparent",
                        borderRadius: "10px",
                        bottom: "-50px"
                    }}
                >
                    <ul className={styles.dots}> {dots} </ul>
                </div>
            )
        },
        customPaging: i => {
            return (
                <div style={{ color: 'transparent' }}>{i} </div>
            )
        }
    };
    function SampleNextArrow(props) {
        const { onClick } = props;
        const handleNext = () => {
            setIsManualScroll(true);
            setSpeed(1000);
            next();
            onClick();
        }
        return (
            <div
                className={styles.arrow__right}
                onClick={handleNext}
            >
                <svg width="21" height="40" viewBox="0 0 21 40" fill="none">
                    <path
                        d="M1.78516 1.6095L19.7852 20.1095L1.78516 38.6095"
                        stroke="#00D264"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { onClick } = props;
        const handlePrev = () => {
            setIsManualScroll(true);
            back();
            onClick();
        }
        return (
            <div
                className={styles.arrow__left}
                onClick={handlePrev}
            >
                <svg width="21" height="38" viewBox="0 0 21 38" fill="none">
                    <path
                        d="M19.7852 36.059L1.78515 18.559L19.7852 1.05896"
                        stroke="#00D264"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>
        );
    }

    
    useEffect(() => {
        if (isVisible)
            play();
    }, [isVisible])

    useEffect(() => {
        if (!isManualScroll) {
            play();
        }
    }, [isManualScroll]);

    const handleTouchStart = () => {
        setIsManualScroll(true)
        setCursor('grabbing');
      };
    
      const handleTouchEnd = () => {
        setIsManualScroll(false)
        setCursor('default');
      };

    // manejadores
    const handleSwipe = (direction) => {
        pause();
        setIsManualScroll(true);
    };

    // Funciones de slider
    const next = () =>{
        sliderRef.slickNext();
    }
    const back = () =>{
        sliderRef.slickPrev();
    }
    const play = () => {
        sliderRef.slickPlay();
    };

    const pause = () => {
        sliderRef.slickPause();
    };
    
    return (
        <div className={context === 'clients' ? styles.background__white : styles.background}>
            <div className={styles.container} >
                {context !== 'clients' && (
                    <h3><FormattedMessage id="clients.caseStudies-title" /></h3>
                )}
                <div ref={containerRef} className={styles.slider}   >
                    <Slider
                        {...settings}
                        easing='easing-in'
                        className="clientsSummary-slider"
                        ref={slider => (sliderRef = slider)}
                        swipe={true}
                        swipeToSlide={true}
                        pauseOnHover={true}
                        onSwipe={handleSwipe}
                        style={{ cursor }} 
                        onMouseDown={handleTouchStart} onMouseUp={handleTouchEnd}
                    >
                        {dataCaseStudies.map((client) => {
                            if (context === client.id) return;
                            const renderClient = () => {
                                return (
                                    <>
                                        {client.img}
                                        {client.logo}
                                        <p>
                                            {client.text}
                                        </p>
                                        <div className={styles.viewCase}  >
                                            <span>
                                                <FormattedMessage id="clients.caseStudies-link" />
                                            </span>
                                            <svg
                                                width="17"
                                                height="17"
                                                viewBox="0 0 17 17"
                                                fill="none"
                                                className={styles.arrowView}
                                            >
                                                <path
                                                    d="M1.5 15.5L16 1M16 1H1.5M16 1V13.5"
                                                    stroke="currentColor"
                                                    strokeWidth="1"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </>
                                )
                            }
                            return (
                                <Link to={`/${lang}/clients${client.url}`} className={styles.item} key={client.id}>
                                    {renderClient()}
                                </Link>
                            )
                        })}
                    </Slider>
                </div>
            </div>
        </div>

    )
}