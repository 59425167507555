import { FormattedMessage } from "react-intl";
import { Footer, Head, TopBar, Contact, WelfiLogo2 } from "../../components"
import { Suspense, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import styles from './clients.module.scss';
import { ClientsPageTitle, CaseStudiesSummary, ClientsIcons, ClientsComments, ClientsDescription, WelfiInfographic } from "../../modules";
import welfi from './../../assets/images/clients/welfi illustration.webp';
import ReactGA from 'react-ga';

export const ClientsWelfiPage = () => {
    useEffect(() => {
        ReactGA.pageview('/clients/welfi');
        window.scrollTo(0, 0);
    }, [])
    const renderLoader = () => <div className="spinner"><CircularProgress color="inherit" /></div>;
    return (

        <main>
            <Head
                title='Welfi Case Study | Wakapi'
                description='An integrated solution to deliver fast, comprehensive financial education and advice.'
                path='/clients/welfi'
                haveCarousel={true}
            />
            {/* Barra de navegación */}
            <TopBar />

            {/* Titulos */}
            <section className={styles.title__container}>
                <div className={styles.title}>
                    <h1>
                        <FormattedMessage id="clients.section" />
                    </h1>
                    <h3>
                        <FormattedMessage id="clients.title" />
                    </h3>
                </div>
            </section>

            {/* Welfi */}
            <section>
                <div className={styles.background}>
                    <ClientsPageTitle
                        logo={<WelfiLogo2 size={{width:'282',height:'200'}}/>}
                        text={<FormattedMessage id="clients.caseStudies-summary-welfi" />}
                    />
                    <div className={styles.infographic}>
                        <WelfiInfographic />
                    </div>
                    <ClientsDescription
                        client='welfi'
                        img={<img src={welfi} alt="Welfi Fintech Solution Development" width={368} height={310} />}
                        marginImg="0"
                    />
                </div>
            </section>
            {/* Generico clientes */}
            <section className={styles.clients__section}>
                {/* Casos de éxito */}
                <CaseStudiesSummary context='welfi' />

                {/* Logos de clientes */}
                <ClientsIcons />

                {/* Opinines */}
                <ClientsComments />
            </section>

            {/* Contacto */}
            <section className="contact__container">
                <Suspense fallback={renderLoader()}>
                    <Contact />
                </Suspense>
            </section>

            {/* Footer */}
            <Footer />
        </main>
    )
}