import styles from './bannerImageAnimated.module.scss';

export const BannerImageAnimated = ({ imgBackground, imgFront, altBackground, altFront, isVisible, highPriority }) => {
    return (
        <div className={styles.banner_img}>
            <div className={styles["banner__gradient--mobile"]} />
            <img
                fetchpriority={highPriority ? "high" : "low"}
                src={imgBackground} alt={altBackground}
                className={isVisible ? styles["banner__imgBackground--animation"] : styles["banner__imgBackground"]}
                width={877} height={533}
            />
            <img
                fetchpriority={highPriority ? "high" : "low"}
                src={imgFront} alt={altFront}
                className={isVisible ? styles["banner__imgFront--animation"] : styles["banner__imgFront"]}
                width={877} height={533}
            />
        </div>
    )
}