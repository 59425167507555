import React, { Suspense, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { ClientsComments, CaseStudiesSummary, CircleBackgroundSvg, ClientsIcons } from "../../modules";
import styles from "./clients.module.scss";
import ReactGA from 'react-ga';
import { CircularProgress } from "@mui/material";
import { Head, Footer, TopBar, Contact } from "../../components";

const Clients = () => {
  useEffect(() => {
    ReactGA.pageview('/clients');
    window.scrollTo(0, 0);
  }, [])

  const renderLoader = () => <div className="spinner"><CircularProgress color="inherit" /></div>;

  return (
    <main>
      {/* Encabezados y meta descriptions */}
      <Head
        title={'Our Clients | Wakapi'}
        description={'Discover the companies that trusted us to accelerate their digital journey, and explore our success stories!'}
        path={"/clients"}
        haveCarousel={true}
      />

      {/* Barra de navegación */}
      <TopBar />

      {/* Titulos */}
      <section className={styles.title__container}>
        <div className={styles.title}>
          <h1>
            <FormattedMessage id="clients.section" />
          </h1>
          <h3>
            <FormattedMessage id="clients.title" />
          </h3>
        </div>
      </section>

      {/* Casos de éxito */}
      <div className={styles.background}>
        {/* Fondo con forma circular */}
        <CircleBackgroundSvg />
        <div className={styles.background__summary}>
          <CaseStudiesSummary context='clients' />
        </div>
        {/* Logos de clientes */}
        <ClientsIcons />
      </div>
      {/* Opiniones */}
      <section className={styles.clients__section}>
        <ClientsComments isHome={false} />
      </section>

      {/* Contacto */}
      <section className="contact__container">
        <Suspense fallback={renderLoader()}>
          <Contact />
        </Suspense>
      </section>

      {/* Footer */}
      <Footer />
    </main>
  );
};
export default Clients;
