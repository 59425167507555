import { FormattedMessage } from "react-intl";
import { Footer, Head, TopBar,Contact, ThomsonReutersLogo2 } from "../../components"
import { Suspense, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import styles from './clients.module.scss';
import { ClientsPageTitle, CaseStudiesSummary, ClientsIcons, ClientsComments, ClientsDescription, ThomsonReutersInfographic } from "../../modules";
import thomsonReuters from "./../../assets/images/clients/wakapi-outsourcing-client-thomsonreuters3.webp";
import ReactGA from 'react-ga';


export const ClientsThomsonReutersPage = () => {
    useEffect(() => {
        ReactGA.pageview('/clients/thomson-reuters');
        window.scrollTo(0, 0);
      }, [])
    const renderLoader = () => <div className="spinner"><CircularProgress color="inherit" /></div>;
    return (

        <main>
            <Head
                title='Thomson Reuters Case Study | Wakapi'
                description='Secure and efficient data migration to improve performance and connect all critical areas of the operation.'
                path='/clients/thomson-reuters'
                haveCarousel={true}
            />
            {/* Barra de navegación */}
            <TopBar />

            {/* Titulos */}
            <section className={styles.title__container}>
                <div className={styles.title}>
                    <h1>
                        <FormattedMessage id="clients.section" />
                    </h1>
                    <h3>
                        <FormattedMessage id="clients.title" />
                    </h3>
                </div>
            </section>

            {/* Thomson reuters */}
            <section>
                <div className={styles.background}>
                    <ClientsPageTitle
                        logo={<ThomsonReutersLogo2 size={{width:'576',height:'200'}}/>}
                        text={<FormattedMessage id="clients.caseStudies-summary-thomsom" />}
                    />
                    <div className={styles.infographic}>
                        <ThomsonReutersInfographic />
                    </div>
                    <ClientsDescription
                        client='thomson-reuters'                        
                        img={ <img src={thomsonReuters} alt="Outsourcing Client Thomson Reuters 3" width={486} height={210}/>}
                        marginImg="0"
                    />
                </div>
            </section>
           {/* Generico clientes */}
           <section className={styles.clients__section}>
                {/* Casos de éxito */}
                <CaseStudiesSummary context='thomson-reuters' />

                {/* Logos de clientes */}
                <ClientsIcons />

                {/* Opinines */}
                <ClientsComments />
            </section>

            {/* Contacto */}
            <section className="contact__container">
                <Suspense fallback={renderLoader()}>
                    <Contact />
                </Suspense>
            </section>

            {/* Footer */}
            <Footer />
        </main>
    )
}