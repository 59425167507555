import { FormattedMessage } from "react-intl";
import { Footer, Head, TopBar, Contact, PinPointLogo2 } from "../../components"
import { Suspense, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import styles from './clients.module.scss';
import { ClientsPageTitle, CaseStudiesSummary, ClientsIcons, ClientsComments, ClientsDescription, PinPointInfographic } from "../../modules";
import ReactGA from 'react-ga';
import pinPoint2 from "./../../assets/images/clients/outsourcing-services-client-success-pinpoint2.webp";

export const ClientsPinPointPage = () => {
    useEffect(() => {
        ReactGA.pageview('/clients/pinpoint');
        window.scrollTo(0, 0);
    }, [])
    const renderLoader = () => <div className="spinner"><CircularProgress color="inherit" /></div>;
    
    return (

        <main>
            <Head
                title='PinPoint Case Study | Wakapi'
                description='Integrated software solutions to assure product integrity and improve production processes.'
                path='/clients/pinpoint'
                haveCarousel={true}
            />
            <TopBar />
            {/* Titulos */}
            <section className={styles.title__container}>
                <div className={styles.title}>
                    <h1>
                        <FormattedMessage id="clients.section" />
                    </h1>
                    <h3>
                        <FormattedMessage id="clients.title" />
                    </h3>
                </div>
            </section>

            {/* PinPoint */}
            <section>
                <div className={styles.background}>
                    <ClientsPageTitle
                        logo={<PinPointLogo2 size={{width:'317',height:'200'}}/>}
                        text={<FormattedMessage id="clients.caseStudies-summary-pinPoint" />}
                    />
                    <div className={styles.infographic}>
                        <PinPointInfographic />
                    </div>
                    <ClientsDescription
                        client='pinPoint'
                        img={<img src={pinPoint2} alt="Client Success Story PinPoint 2" width={252} height={252} />}
                    />
                </div>
            </section>

            {/* Generico clientes */}
            <section className={styles.clients__section}>
                {/* Casos de éxito */}
                <CaseStudiesSummary context='pinpoint' />

                {/* Logos de clientes */}
                <ClientsIcons />

                {/* Opinines */}
                <ClientsComments />
            </section>

            {/* Contacto */}
            <section className="contact__container">
                <Suspense fallback={renderLoader()}>
                    <Contact />
                </Suspense>
            </section>

            {/* Footer */}
            <Footer />
        </main>
    )
}