import { FormattedMessage } from "react-intl";
import { Footer, Head, TopBar, Contact, AmazonLogo2 } from "../../components"
import { Suspense, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import styles from './clients.module.scss';
import { ClientsPageTitle, CaseStudiesSummary, ClientsIcons, ClientsComments, AmazonInfographic, ClientsDescription } from "../../modules";
import ReactGA from 'react-ga';
import amazon2 from "./../../assets/images/clients/amazon-client-outsourcing-success3.webp";

export const ClientsAmazonPage = () => {
    const renderLoader = () => <div className="spinner"><CircularProgress color="inherit" /></div>;
    useEffect(() => {
        ReactGA.pageview('/clients/amazon');
        window.scrollTo(0, 0);
    }, [])
    return (

        <main>
            {/* Encabezados y meta descriptions */}
            <Head
                title='Amazon Case Study | Wakapi'
                description="A functional prototype solution that converts the merchant's catalogs to an Amazon.com-compliant XML data feed."
                path='/clients/amazon'
                haveCarousel={true}
            />

            {/* Barra de navegación */}
            <TopBar />

            {/* Titulos */}
            <section className={styles.title__container}>
                <div className={styles.title}>
                    <h1>
                        <FormattedMessage id="clients.section" />
                    </h1>
                    <h3>
                        <FormattedMessage id="clients.title" />
                    </h3>
                </div>
            </section>

             {/* Amazon */}
            <section>
                <div className={styles.background}>
                    <ClientsPageTitle
                        logo={<AmazonLogo2 size={{width:'346',height:'200'}}/>}
                        text={<FormattedMessage id="clients.caseStudies-summary-amazon" />}
                    />
                    <div className={styles.infographic}>
                        <AmazonInfographic />
                    </div>
                    <ClientsDescription
                        client='amazon'
                        img={<img src={amazon2} alt="Amazon Client Software Outsourcing Success 3" width={471} height={121} />}
                        marginImg="0"
                    />
                </div>
            </section>
            {/* Generico clientes */}
            <section className={styles.clients__section}>
                {/* Casos de éxito */}
                <CaseStudiesSummary context='amazon' />

                {/* Logos de clientes */}
                <ClientsIcons />

                {/* Opinines */}
                <ClientsComments />
            </section>

            {/* Contacto */}
            <section className="contact__container">
                <Suspense fallback={renderLoader()}>
                    <Contact />
                </Suspense>
            </section>

            {/* Footer */}
            <Footer />
        </main>
    )
}