import React, { useState, useContext, useEffect } from "react";
import styles from "./topBar.module.scss";
import logowakapi from "../../assets/images/logos/wakapi-logo.svg";
import menuHamburguer from "../../assets/images/iconos/menu-hamburguer.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { langContext } from "../../context/langContext";
import { FormattedMessage } from "react-intl";
import { Chevron } from "../../assets/icons/generic";
import { AboutUsButton } from "./AboutUsButton";
import { ServicesButton } from "./ServicesButton";
import { ClientsButton } from "./ClientsButton";
import { TeamButton } from "./TeamButton";
import { AwaVenturesButton } from "./AwaVenturesButton";
import { ContactButton } from "./ContactButton";
import { LangButton } from "./LangButton";
import { ListTechnologies, TechnologyButton } from "./TechnologyButton";

export const TopBar = () => {

  //Hooks

  const location = useLocation();
  const language = useContext(langContext);
  const [languageSelected, setLanguageSelected] = useState(language.lang || localStorage.getItem('lang'));

  //States

  const [y] = useState(window.scrollY);


  const [isMenuExtended, setIsMenuExtended] = useState(false);
  const [isTechnologiesExtended, setIsTechnologiesExtended] = useState(false);
  const toggleTechnologies = () => setIsTechnologiesExtended(!isTechnologiesExtended);
  const path = location.pathname;
   //Ancho de ventana
   const [width, setWidth] = useState(window.innerWidth);
   useEffect(() => {
     //Seteo de ancho de ventana y cambios
     const handleResize = () => setWidth(window.innerWidth);
     window.addEventListener('resize', handleResize);
     return () => {
       window.removeEventListener('resize', handleResize);
     };
   }, []);
 

  useEffect(() => {
    window.addEventListener("scroll", (e) => handleScroll(e));
    return () => {
      window.removeEventListener("scroll", (e) => handleScroll(e));
    };
  }, [y]);

  //Funciones

  const toggleMenu = () => {
    setIsMenuExtended(!isMenuExtended);
  };

  const handleScroll = () => {
    setIsMenuExtended(false);
  }

  useEffect(() => {
    console.log('isTechnologiesExtended', isTechnologiesExtended)
    console.log('isMenuExtended', isMenuExtended)
  }, [isMenuExtended, isTechnologiesExtended])


  // Renderizado de navegacion
  const Nav = () => {
    return (
      <>
        <AboutUsButton path={path} lang={languageSelected} />
        <ServicesButton path={path} lang={languageSelected} />
        <TechnologyButton path={path} lang={languageSelected} setIsExtended={setIsTechnologiesExtended} isExtended={isTechnologiesExtended} toggle={toggleTechnologies} />
        <ClientsButton path={path} lang={languageSelected} />
        <TeamButton path={path} lang={languageSelected} />
        <AwaVenturesButton />
        <ContactButton path={path} lang={languageSelected} toggleMenu={toggleMenu} />
        <LangButton path={path} setLanguageSelected={setLanguageSelected} languageSelected={languageSelected} />
      </>
    );
  };


  return (

    <div className={styles.background}>

      <div className={styles.container}>
        <Link to={`/${languageSelected}/`} style={{ textDecoration: "none" }}>
          <div className={styles.logo}>
            <img src={logowakapi} alt="logo wakapi" width={206} height={48} />
          </div>
        </Link>


        <div className={styles.menuContainer}>
          <div className={styles["menu--hamburguer"]}>
            <div className={styles.hamburguer} onClick={toggleMenu}>
              <img src={menuHamburguer} alt="arrow" width={25} height={25} />
            </div>
          </div>
          {/* Desktop */}
          <nav className={styles.menu}>
            <Nav />
          </nav>
        </div>

      </div>

      {/* Mobile */}
      <nav
        className={styles.menuResponsive}
        style={{ display: (isMenuExtended && width < 1280) ? "flex" : "none", paddingLeft: isTechnologiesExtended ? '24px' : '10%'}}
      >
        {isTechnologiesExtended ?
          <ListTechnologies lang={languageSelected} path={path} mobile toggle={toggleTechnologies}/>
          :
          <Nav />
        }

      </nav>



    </div>
  );
};

export default TopBar;
